<template>
    <div class="header-container">
        <div class="l-content">
            <!-- <el-button icon="el-icon-menu" size="mini"></el-button> -->
            <!-- 面包屑 -->
            <!-- <span class="text">首页</span> -->
            <el-form :inline="true">
                <!-- <el-form :inline="true" :model="userForm"> -->
                <!-- <el-form-item>
                    <div class="div">
                    <el-input placeholder="请输入信息内容"></el-input>
                    </div>
                </el-form-item> -->
                <!-- <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <div class="r-content">
            <el-dropdown>
                <span class="el-dropdown-link">
                    <img class="user" src="../assets/images/user.png" alt="">
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>个人中心</el-dropdown-item>
                    <el-dropdown-item>退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    data() {
        return {}
    },
    created() { },
    mounted() { }, 
    methods: {},
    components: {}
}
</script>

<style lang="scss" scoped>
.header-container {
    // padding: 0 20px;
    margin: 0;
    padding: 0;
    background-color: #f8f9ff;
    height: 89px;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dadada;
    .text {
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
    }
    .r-content{
        .user{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

}
</style>
