<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    background-color="#081a51"
    text-color="#ccd2e3"
    active-text-color="#ffffff"
  >
    <div class="logo-box">
      <div class="logo">
        <img src="../assets/images/logo.png" />
      </div>
      <p>安必思</p>
    </div>
    <p class="p1">主菜单</p>
    <el-menu-item
      @click="clickMeun(item)"
      v-for="item in menuData"
      :key="item.name"
      :index="item.path + ''"
    >
      <!-- <i :class="`el-icon-${item.icon}`"></i> -->
      <img :src="item.img" />
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <p class="p2">操作区</p>
    <el-menu-item
      @click="clickMeun(item)"
      v-for="item in noChildren"
      :key="item.name"
      :index="item.path + ''"
    >
      <!-- <i :class="`el-icon-${item.icon}`"></i> -->
      <img :src="item.img" />
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu
      v-for="item in hasChildren"
      :key="item.name"
      :index="item.path + ''"
    >
      <template slot="title">
        <img :src="item.img" />
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group
        v-for="subItem in item.children"
        :key="subItem.path"
        :index="subItem.id"
      >
        <el-menu-item
          style="
            font-size: 6px;
            line-height: 20px;
            height: 20px;
            padding-left: 60px;
          "
          @click="clickMenu2(subItem)"
          :index="subItem.path"
          >{{ subItem.label }}</el-menu-item
        >
      </el-menu-item-group>
    </el-submenu>
    <p class="p2">一般</p>
    <el-menu-item
      @click="clickMeun1(item)"
      v-for="item in menuData2"
      :key="item.name"
      :index="item.path + ''"
    >
      <!-- <i :class="`el-icon-${item.icon}`"></i> -->
      <img :src="item.img" />
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
  </el-menu>
</template>

<style lang="less" scoped>
@font-face {
  font-family: Ali;
  src: url(https://puhuiti.oss-cn-hangzhou.aliyuncs.com/AlimamaShuHeiTi/AlimamaShuHeiTi-Bold/AlimamaShuHeiTi-Bold.ttf)
    format("TrueType");
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 260px;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;

  ::-webkit-scrollbar {
    width: 0px;
  }

  p {
    font-family: "Ali";
    font-weight: bold;
  }

  span {
    font-family: "Ali";
  }

  img {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-left: 8px;
    padding-right: 8px;
  }
}

.el-menu {
  width: 260px;
  height: 100vh;
  margin: 0;

  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400px;
  }
}

.p1 {
  color: #51cbff;
  font-size: 12px;
  margin-left: 30px;
  margin-top: 48px;
}

.p2 {
  color: #51cbff;
  font-size: 12px;
  margin-left: 30px;
  margin-top: 30px;
}

.logo-box {
  display: flex;
  align-items: center;
  margin-top: 10px;

  p {
    font-size: 24px;
    color: #ffffff;
    margin-left: 14px;
    font-family: "Ali";
  }

  .logo {
    width: 40px;
    height: 40px;
    background-color: #017efa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 30px;

    img {
      margin: 0;
      padding: 0;
    }
  }
  .el-sub-menu:hover {
    background-color: aqua !important;
  }
  .el-sub-menu__title:focus,
  .el-sub-menu__title:hover {
    background-color: aqua !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          path: "/home",
          name: "home",
          img: require("../assets/images/Chart_fill.png"),
          label: "概况",
          url: "home",
        },
        {
          path: "/visual",
          name: "visual",
          label: "可视化大屏",
          img: require("../assets/images/Chat.png"),
          url: "visual",
        },
      ],
      menuData1: [
        {
          path: "/liveData",
          name: "liveData",
          img: require("../assets/images/User_box.png"),
          label: "实时数据",
          url: "liveData",
        },
        {
          label: "往期数据",
          img: require("../assets/images/wangqishuju.png"),
          id: 0,
          path: "/past",
          children: [
            {
              path: "/temperature",
              name: "temperature",
              label: "温度数据",
              url: "temperature",
            },
            {
              path: "/valve",
              name: "valve",
              label: "阀门数据",
              url: "valve",
            },
            {
              path: "/psi",
              name: "psi",
              label: "出油量数据",
              url: "psi",
            },
            {
              path: "/switch",
              name: "switch",
              label: "开关数据",
              url: "switch",
            },
            {
              path: "/clean",
              name: "clean",
              label: "隔油日清洗状态",
              url: "clean",
            },
            {
              path: "/push",
              name: "push",
              label: "推进启停命令",
              url: "push",
            },
          ],
        },
        {
          path: "/parameter",
          name: "parameter",
          label: "参数设置",
          img: require("../assets/images/Search_alt.png"),
          url: "parameter",
        },
        {
          path: "/add",
          name: "add",
          label: "设备管理",
          img: require("../assets/images/Chart_alt.png"),
          url: "add",
        },
        {
          label: "预警数据",
          img: require("../assets/images/Chart_alt.png"),
          id: 1,
          children: [
            {
              path: "/warn",
              name: "warn",
              label: "实时预警",
              url: "warn",
            },
            {
              path: "/warn1",
              name: "warn1",
              label: "往期预警",
              url: "warn1",
            },
          ],
        },
      ],
      menuData2: [
        {
          path: "/user",
          name: "user",
          img: require("../assets/images/Folder_alt.png"),
          label: "用户管理",
          url: "user",
        },
        {
          path: "/set",
          name: "set",
          label: "设置",
          img: require("../assets/images/Setting_alt_line.png"),
          url: "set",
        },
      ],
    };
  },
  computed: {
    // 没有子菜单
    noChildren() {
      return this.menuData1.filter((item) => !item.children);
    },
    // 有子菜单
    hasChildren() {
      return this.menuData1.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    //点击菜单
    clickMeun(item) {
      // 当前页面的路由与跳转的路由不一致的时候跳转
      if (
        this.$route.path != item.path &&
        !(this.$route === "/home" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
    },
    //点击菜单
    clickMeun1(item) {
      // 当前页面的路由与跳转的路由不一致的时候跳转
      if (
        this.$route.path != item.path &&
        !(this.$route === "/home" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
    },
    clickMenu2(subItem) {
      if (
        this.$route.path != subItem.path &&
        !(this.$route === "/home" && subItem.path === "/")
      ) {
        this.$router.push(subItem.path);
      }
    },
  },
};
</script>
