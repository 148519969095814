<template>
    <div class="center">
        <div class="password">
            <p>修改密码</p>
            <el-form :model="form1" :rules="guize">
                <el-form-item label="旧 密 码" label-width="120px" prop="oldPass">
                    <el-input  type="password"  v-model="form1.oldPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新 密 码" label-width="120px" prop="newPass">
                    <el-input type="password" v-model="form1.newPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" label-width="120px" prop="newPasss">
                    <el-input  type="password"  v-model="form1.newPasss" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit1">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
export default {
    data () {
        return {
            form1: {
                oldPass:'',
                newPass:'',
                newPasss:''
            },
            guize:{
                oldPass: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                newPass:[
                { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                newPasss: [
                { required: true, message: '请输入密码', trigger: 'blur' },
                ],
            }
        }
    },
    created () { },
    mounted () { },
    computed: {
        ...mapState({ loginModule: state => state.loginModule.userinfo }),
    },
    methods: {
        submit1(){
            let jwt = this.loginModule.token
            this.axios({
                url:'https://www.anbisibiz.cn/sys/user/edit/pass?oldPass=' + this.form1.oldPass + '&newPass=' + this.form1.newPass + '&newPasss=' + this.form1.newPasss,
                method: 'post',
                headers:{
                    ['access-token']: jwt
                },
            }).then((res=>{
                // console.log(res.data.status)
                if(res.data.status === 200){
                    this.$message.success('修改密码成功')
                }else{
                    this.$message.error(res.data.message)
                }
            })).catch((err=>{
                this.$message.error('修改密码失败')
            }))
        }
    },
    components: {}
}
</script>

<style lang="scss" scoped>
.password{
    width: 300px;
    height: 300px;

}
.dialog-footer{
    width: 30px;
    margin: 0 auto;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
}
</style>
