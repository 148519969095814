<template>
    <div class="box" style="width: 100%;">
        <div style="width: 100%;height: 100%;">
            <div style="width: 100%;margin: 0 auto">
                <span style="font-size: 20px;font-weight:bolder">故障实时数据</span>
                <!-- <div class="block" style="display: flex;margin-top: 20px;">
                    <el-form :inline="true">
              <el-form-item>
                <div class="item1">
                  <el-input placeholder="请输入查询内容" v-model="company" style="width:278px;height:42px;background: #f2f2f2;"></el-input>
                </div>
              </el-form-item>
            </el-form>
                    <el-date-picker v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                <el-button @click="query" type="primary" style="width:100px;height:42px;font-size: 16px;margin-left: 10px;">查询</el-button>
                </div> -->
                <div  class="common-tabel" style="margin-top: 10px;padding-top: 10px;">
                    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)" stripe height="90%" :data="tableData.slice((currentPage - 1) * PageSize, currentPage * PageSize)"
                        style="width: 100%," :cell-style="{ 'text-align': 'center' }">
                        <el-table-column align="center" prop="id" label="id">
                        </el-table-column>
                        <el-table-column align="center" prop="device_id" label="设备序列号">
                        </el-table-column>
                        <el-table-column align="center" prop="device_company" label="所属单位">
                        </el-table-column>
                        <el-table-column align="center" prop="warn_name" label="故障名称">
                        </el-table-column>
                        <el-table-column align="center" prop="warn_time" label="开始时间">
                        </el-table-column>
                        <!-- <el-table-column align="center" prop="end_time" label="结束时间">
                        </el-table-column> -->
                    </el-table>
                    <div class="pager">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
    data() {
        return {
            swiperOptions: {
                // loop: true,
                direction: "vertical",
                autoplay: {
                    delay: 2000
                }
            },
            warn: [{}],
            // tableData: [{}],
            // 总数据
            tableData: [],
            // 默认显示第几页
            currentPage: 1,
            // 总条数，根据接口获取数据长度(注意：这里不能为空)
            totalCount: 1,
            // 个数选择器（可修改）
            pageSizes: [12, 20, 30, 40],
            // 默认每页显示的条数（可修改）
            PageSize: 12,
            value1: [new Date(2023, 0, 1, 0, 0 , 0,),new Date(2033, 0, 1, 23, 59 , 59,)],
            company:'',
            loading: false
            
        }
    },
    created() {
        this.getNowTime()
    },
    computed: {
        ...mapState({ loginModule: state => state.loginModule.userinfo }),
    },
    mounted() {
        this.getlist()
        this.getwarn()
        // this.timer = setInterval(() => {
        //     this.getwarn();
        // }, 10000);
    },
    methods: {
        getNowTime() {
	       var now = new Date();
	       var year = now.getFullYear(); //得到年份
	       var month = now.getMonth(); //得到月份
	       var date = now.getDate(); //得到日期
	       var hour = now.getHours(); //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
           var minute = now.getMinutes();
           var second = now.getSeconds()
	       month = month.toString().padStart(2, "0");
	       date = date.toString().padStart(2, "0");
	    //    this.date = new Date(`${year},${month},${date},${hour},${minute},${second}`);
            let time = new Date(year,month,date,hour,minute,second)
            this.value1[1] = time
        //    console.log(this.date)
        //    console.log(this.value1)
	    },
        query() {
            let jwt = this.loginModule.token
            let value = this.$moment(this.value1[0]).format('YYYY-MM-DD HH:mm:ss')
            let value1 = this.$moment(this.value1[1]).format('YYYY-MM-DD HH:mm:ss')
            let company = this.company
            // console.log(value)
            this.axios({
                url: "https://www.anbisibiz.cn/oper/query?company="+ company + "&startTime=" + value + "&endTime=" + value1,
                method: "post",
                headers: {
                    ['access-token']: jwt
                },
                // data:{
                //     startTime: value
                // }
            }).then((res => {
                // console.log('res', res)
                this.tableData = res.data.data
                this.totalCount = res.data.data.length
                this.currentPage = 1
            }))
        },
        getwarn() {
            let jwt = this.loginModule.token
            this.axios({
                url: "https://www.anbisibiz.cn/device/warn",
                method: "get",
                headers: {
                    ['access-token']: jwt
                },
            }).then((res => {
                // console.log(1111, res.data.data)
                this.warn = res.data.data
                // console.log(12121, this.warn)
            })).catch((err => {
                // console.log(err)
            }))
        },
        getlist() {
            let jwt = this.loginModule.token
            this.loading = true
            this.axios({
                url: "https://www.anbisibiz.cn/device/warn",
                method: "get",
                headers: {
                    ['access-token']: jwt
                }
            }).then((res => {
                // console.log(11111111111, res.data.data)
                this.tableData = res.data.data
                this.totalCount = res.data.data.length
                this.loading = false
            }))
        },
        handleSizeChange(val) {
            // 改变每页显示的条数 
            this.PageSize = val
            // 注意：在改变每页显示的条数时，要将页码显示到第一页
            this.currentPage = 1
            this.getlist()

        },
        // 显示第几页
        handleCurrentChange(val) {
            // 改变默认的页数
            this.currentPage = val
            this.getlist()
        },
    },
    components: {}
}
</script>

<style lang="scss" scoped>
.box {
    display: flex;
}

.top {
    background: red;
    width: 100%;
    height: 30px;
    margin: 10px auto;
}

.swiper-container {
  width: 100%;
  height: 30px;
  border: 1px solid #007acc;
  text-align: center;
  :hover{
      cursor: pointer;
    }
  span {
    line-height: 30px;
  }
}

.common-tabel {
    position: relative;
    height: 800px;

    .pager {
        position: absolute;
        bottom: 0;
        right: 20px;
    }
}

/deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
}

// /deep/ .el-table__row{
//   :hover{
//     background-color: #000000;
//   }
// }
/deep/ td {
    padding: 0px;
    // background: #f5f5f5;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
}

/deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
}
</style>
