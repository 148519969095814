<template>
    <div>
        <el-container>
            <el-aside width="260px">
                <common-aside />
            </el-aside>
            <el-container>
                <el-header height="90px">
                    <common-header />
                </el-header>
                <el-main style="background-color: #f8f9ff;" >
                    <!-- 路由出口 -->
                    <!-- 路由匹配到的组件将渲染在这里 -->
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue';
export default {
    data() {
        return {}
    },
    created() { },
    mounted() { },
    methods: {},
    components: {
        CommonAside,
        CommonHeader
    }
}
</script>

<style lang="scss" scoped>
.el-aside::-webkit-scrollbar {
      display: none;
   }
    .el-header{
        padding: 0;
        margin: 0;
    }

</style>
