<template>
  <div class="box" style="width: 100%;">
    <div style="width: 100%;">
      <div class="top">
        <swiper ref="myside" :options="swiperOptions" @click="click">
          <swiper-slide v-for="item in warn">
            <span>分店名称：{{ item.device_company }} 故障名称：{{ item.warn_name }} 时间：{{ item.warn_time }}</span>
            <!-- 分店名称：{{ item. }} -->
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div v-else="warn == ''" class="top1">
      <span class="span">当前无预警</span>
      </div> -->
      <div class="box1">
        <div style="width: 480px;">
          <span style="font-size: 20px;font-weight:bolder">设备列表</span>
          <div class="left-bottom" style="margin-top: 20px;">
            <div class="manage-header" style="width:480px;padding-top: 10px; margin-left: 15px;">
              <!-- <el-button @click="handleAdd" type="primary">
                + 新增
            </el-button> -->
              <!-- form搜索区域 -->
              <el-form :inline="true">
                <el-form-item>
                  <div class="item1">
                    <el-input placeholder="请输入查询内容" v-model="parm"
                      style="width:370px;height:42px;background: #f2f2f2;"></el-input>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button @click="onSubmit" type="primary" style="width:73px;height:42px">搜索</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div style="height: 629px;overflow:auto">
              <el-table :data="tableData" @row-click="rowClick" :disabled="false" style="width: 100%;"
                highlight-current-row :header-cell-style="{ width: '100%', fontsize: '16px', color: 'black' }">
                <el-table-column prop="device_id" label="设备编号">
                </el-table-column>
                <el-table-column prop="device_company" label="所属单位">
                </el-table-column>
                <el-table-column prop="device_name" label="分店名称">
                </el-table-column>
                <el-table-column prop="device_position" label="所在地点">
                </el-table-column>
                <el-table-column prop="" label="设备状态">
                  <template slot-scope="scope" style="align-items: center;">
                    <img v-if="scope.row.device_status == 'offline'" src="../assets/images/status1.png"
                      style="height: 12px;padding-right: 10px;" />
                    <img v-else="scope.row.device_status == 'offline'" src="../assets/images/status.png"
                      style="height: 12px;padding-right: 10px;" />
                    <span>{{ scope.row.device_status == 'offline' ? '离线' : '在线' }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.2)" class="left" style="width: 90%;margin-left: 20px;">
          <div style="display: flex; justify-content: space-between;align-items: center; ">
            <span style="font-size: 20px;font-weight:bolder">后台参数</span>
            <el-button type="mini" @click="refresh1" style="text-align: center;background-color: #66b1ff;color: #ffffff;"
              :disabled="flag">刷新</el-button>
          </div>
          <div style="width: 100%;height:750px;background:#ffffff;margin-top: 20px; overflow:auto">
            <div class="param-box">
              <div v-for="item in items" class="param">
                <div @click="updata(item)">
                  {{ item.name }}
                  <p class="input" type="text" :style="{ display: display }">{{ item.data }}</p>
                  <!-- {{ item.data }} -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="修改信息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="form">
        <p>{{ form.name }}</p>
        <el-form-item label="请设置数值">
          <el-input type="number" :oninput="input" v-model="form.data" min="0" ></el-input>
        </el-form-item>
        <p>{{ het }}</p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="off">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
    return {
      swiperOptions: {
        // loop: true,
        direction: "vertical",
        autoplay: {
          delay: 2000
        }
      },
      disabled: true,
      display: 'none',
      dialogVisible: false,
      items: [{
        name: '请点击设备',
        data: ''
      }],
      warn: [{}],
      tableData: [
      ],
      parm: '',
      refresh: '',
      flag: false,
      id: '',
      form: '',
      input:"",
      loading: false,
      het:''
    }
  },
  created() {
  },
  computed: {
    ...mapState({ loginModule: state => state.loginModule.userinfo }),
  },
  mounted() {
    this.getList()
    this.getwarn()
    this.timer = setInterval(() => {
      this.getwarn();
    }, 100000);
  },
  methods: {
    onSubmit() {
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/query?condition=" + this.parm,
        method: 'get',
        headers: {
          ['access-token']: jwt
        },
      }).then((res => {
        // console.log(res.data.data)
        // console.log(res)
        if (res.data.data == null) {
        } else {
          this.tableData = res.data.data
        }


      }));
    },
    click() {
      this.$router.push('/warn');
    },
    getwarn() {
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/warn",
        method: "get",
        headers: {
          ['access-token']: jwt
        },
      }).then((res => {
        this.warn = res.data.data
      })).catch((err => {
        // console.log(err)
      }))
    },
    updata(item) {
      if(item.id === 134 || item.id  === 135 || item.id === 136 || item.id === 137 || item.id === 140 || item.id === 141 || item.id === 144 || item.id === 145 || item.id === 197 || item.id === 200 || item.id === 201 || item.id === 202 || item.id === 206 || item.id === 207){
        this.input = "if(isNaN(value)) { value = parseFloat(value) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)}"
        this.het = '请将参数设置为带一位小数的正数，如是整数请用1.0格式'
      }else{
        this.input = "if(isNaN(value)) { value = parseFloat(value) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.'))}"
        this.het= '请将参数设置为一个正整数'
      }
      this.dialogVisible = true;
      this.form = item
      console.log(item)
      console.log(this.input)
    },
    getList() {
      // 获取的列表的数据
      //         let vm = this;
      //   let jwt = this.loginModule.token
      //   this.axios.get("https://www.anbisibiz.cn/wxuser?pageIndex=1&pageSize=10",
      //   {
      //     headers:{jwt}
      //   }).then((res=>{
      //     console.log(res)
      //     this.tableData = res.data.data.rows
      //     this.total = res.data.data.total
      //   }))
      let vm = this;
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/list",
        method: "get",
        // contentType:"application/json",
        headers: {
          // 'Content-type': 'application/x-www-form-urlencoded',
          ['access-token']: jwt
        },
      })
        // let data = {
        //     ...this.userForm,
        //     pageIndex: 1,
        //     pageSize: 10
        // }
        // this.$api.getViplist({
        //     jwt
        // })
        .then((res => {
          // console.log(res.data.data)
          // console.log(res)
          if (res.data.data == null) {
          } else {
            this.tableData = res.data.data
          }


        }));
    },
    refresh1() {
      let jwt = this.loginModule.token
      let row = this.refresh
      this.flag = true
      // 执行耗时间的代码或事件请求等....
      // console.log(row,111)
      if (row == '') {

      } else {
        this.rowClick(row)
      }
      // 等待事件执行完或者相应的间隔时间后，恢复按钮为可点击状态
      setTimeout(() => {
        this.flag = false
      }, 1000)
    },
    rowClick(row) {
      let jwt = this.loginModule.token
      // this.axios({
      //   url:"https://www.anbisibiz.cn/device/param?id
      // })
      this.refresh = row
      this.id = row.device_id
      // console.log(row.device_status)
      // console.log(this.items[0].name)
      if (row.device_status == "offline") {
        this.items = [{
          name: '设备不在线'
        }]
        this.display = 'none'
      } else {
        this.loading = true
        this.axios.get("https://www.anbisibiz.cn/device/param?id=" + row.device_id, {
          headers: { ['access-token']: jwt }
        }).then((res => {
          console.log(res)
          this.display = 'inline-block'
          this.items = res.data.data.device_param
          this.loading = false
        })).catch((err => {
          // console.log(err)
        }))
      }
    },
    up() {
      let jwt = this.loginModule.token
      this.axios.get("https://www.anbisibiz.cn/device/param?id=" + this.id, {
        headers: { ['access-token']: jwt }
      }).then((res => {
        this.display = 'inline-block'
        this.items = res.data.data.device_param
        this.id = row.device_id
      })).catch((err => {
        // console.log(err)
      }))
    },
    handleClose(done) {
          this.up()
          done();
    },
    off() {
      this.up()
      this.dialogVisible = false;
    },
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-bottom {
  background-color: #ffffff;
  width: 480px;
  height: 750px;
}

.el-table {

  // 看这里！！！！！！！！！！！！！！！！！！！！！！！！！！！！
  // 深度选择器，去除默认的padding
  /deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
  }

  // /deep/ .el-table__row{
  //   :hover{
  //     background-color: #000000;
  //   }
  // }
  /deep/ tbody tr:hover>td {
    // :hover{
    //   background-color: #000000;
    // }
    cursor: pointer;
    background-color: rebeccapurple !important;
    color: #ffffff;
  }

  /deep/ td {
    padding: 2px;
    background: #f5f5f5;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
  }

  /deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
  }
}

/deep/.el-input {
  // 输入框高度
  //  height: 52px;
  // 边框圆角
  border-radius: 10px;
}

/deep/.el-input__inner {
  //  height: 52px;
  background: #f2f2f2;
  // 设置字号
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // 设置输入字体的颜色
  color: #2c2c2c;
}

.box1 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  min-width: 1565px;
  width: 100%;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}

.param-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

.param {
  width: 200px;
  margin-top: 10px;
  background-color: #007acc;
  text-align: right;
  margin-left: 10px;
}

.param div {
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  margin-right: 10px;
}

.input {
  width: 50px;
  background: #f2f2f2;
  color: #000;
  border: #000 1px solid;
}

.top {
  background: red;
  width: 100%;
  height: 30px;
  margin: 10px auto;
}
.top1{
  background: rgb(139, 221, 8);
  width: 100%;
  height: 30px;
  margin: 10px auto;
  text-align: center;
}
.swiper-container {
  width: 100%;
  height: 30px;
  border: 1px solid #007acc;
  text-align: center;

  :hover {
    cursor: pointer;
  }

  span {
    line-height: 30px;
  }
}
</style>
<style lang="scss">
.current-row {
  td {
    background-color: rebeccapurple !important;
    color: #ffffff;
  }
}
</style>
