<template>
    <div class="box" style="width: 100%;">
        <div style="width: 100%;height: 100%;">
            <div style="width: 100%;margin: 0 auto">
                <span style="font-size: 20px;font-weight:bolder">用户管理</span>
                <div style="display: flex;justify-content: space-between;">
                    <div class="block" style="display: flex;margin-top: 20px;">
                        <el-form :inline="true">
                            <el-form-item>
                                <div class="item1">
                                    <el-input placeholder="请输入公司名称" v-model="company"
                                        style="width:278px;height:42px;background: #f2f2f2;"></el-input>
                                    <el-select style="margin-left: 20px;" v-model="status" placeholder="请选择用户状态">
                                    <el-option label="全部用户" :value='values5'></el-option>
                                    <el-option label="正常用户" :value='values3'></el-option>
                                    <el-option label="黑名单用户" :value= 'values4'></el-option>
                                 </el-select>
                                </div>
                            </el-form-item>
                        </el-form>
                        <el-button @click="query" type="primary"
                            style="width:100px;height:42px;font-size: 16px;margin-left: 10px;">查询</el-button>
                    </div>
                    <el-button @click="add" type="primary"
                        style="width:100px;height:42px;font-size: 16px;margin-left: 10px;background:#1cd66c;margin-top: 20px;">添加用户</el-button>
                </div>
                <div class="common-tabel" style="margin-top: 10px;padding-top: 10px;">
                    <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)" stripe height="90%"
                        :data="tableData"
                        style="width: 100%," :cell-style="{ 'text-align': 'center' }">
                        <el-table-column width="80" align="center" prop="id" label="id">
                        </el-table-column>
                        <el-table-column align="center" prop="company" label="公司名称">
                        </el-table-column>
                        <el-table-column align="center" prop="role" label="用户级别">
                        </el-table-column>
                        <el-table-column align="center" prop="branchCompany" label="分店名称">
                        </el-table-column>
                        <el-table-column align="center" prop="username" label="账号">
                        </el-table-column>
                        <el-table-column align="center" prop="phone" label="手机号">
                        </el-table-column>
                        <el-table-column align="center" prop="" label="用户状态">
                            <template slot-scope="scope">
                                <span>{{ scope.row.status == false ? '黑名单用户' : '正常用户' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="300" align="center" label="修改用户信息">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="updata(scope.row)">修改信息</el-button>
                                <el-button style="margin-top: 10px" type="danger" size="mini"
                                    @click="handleDelete(scope.row)">删除</el-button>
                                    <el-button style="margin-top: 10px" type="primary" size="mini"
                                    @click="allot(scope.row)">分配</el-button>
                                    <el-button @click="reset(scope.row)" style="width: 222px;height:20px;margin-top:10px;line-height:0px">重置密码</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pager">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
        <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible">
            <el-form :model="form" :rules="rules">
                <el-form-item label="公司名称" label-width="120px" prop="company">
                    <el-input type="text" v-model="form.company" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分店名称" label-width="120px" prop="branchCompany">
                    <el-input type="text" v-model="form.branchCompany" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址" label-width="120px">
                    <el-input type="text"  v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机" label-width="120px">
                    <el-input type="text"  v-model="form.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户状态" label-width="120px">
                    <el-select v-model="form.status" placeholder="请选择用户状态">
                        <el-option label="正常用户" :value='values'></el-option>
                        <el-option label="黑名单用户" :value= 'values1'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户级别" label-width="120px" prop="role">
                    <el-select v-model="form.role" placeholder="请选择用户级别">
                        <el-option label="超级管理员" :value='value6'></el-option>
                        <el-option label="二级用户" :value='value7'></el-option>
                        <el-option label="三级用户" :value= 'value8'></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="添加新用户" :visible.sync="dialogFormVisible1">
            <el-form :model="form1" :rules="guize">
                <el-form-item label="用户名" label-width="120px" prop="username">
                    <el-input  type="text"  v-model="form1.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户密码" label-width="120px" prop="password">
                    <el-input type="password" v-model="form1.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" label-width="120px" prop="company">
                    <el-input  type="text"  v-model="form1.company" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分店名称" label-width="120px" prop="branchCompany">
                    <el-input type="text" v-model="form1.branchCompany" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="用户级别" label-width="120px">
                    <el-input type="text"  v-model="form1.role" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="用户级别" label-width="120px" prop="role">
                    <el-select v-model="form1.role" placeholder="请选择用户级别">
                        <el-option label="超级管理员" :value='value6'></el-option>
                        <el-option label="二级用户" :value='value7'></el-option>
                        <el-option label="三级用户" :value= 'value8'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="邮箱地址" label-width="120px">
                    <el-input  type="text"  v-model="form1.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机" label-width="120px">
                    <el-input  type="text"  v-model="form1.phone" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submit1">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="分配设备" :visible.sync="dialogFormVisible2">
            
            <el-table
    ref="multipleTable"
    :data="tableData1"
    tooltip-effect="dark"
    style="width: 100%;height: 500px;overflow: auto;"
    @selection-change="handleSelectionChange">
    <el-table-column
      type="selection"
      align="center"
      width="55">
    </el-table-column>
    <el-table-column
      label="设备编号"
      align="center"
      prop="imei"
      width="280">
    </el-table-column>
    <el-table-column
      prop="username"
      align="center"
      label="分店名称"
      width="280">
    </el-table-column>
    <el-table-column
      prop="company"
      align="center"
      label="现所属公司"
      width="280">
    </el-table-column>
  </el-table>


            <div slot="footer" class="dialog-footer">
                <el-button @click="toggleSelection(list)">查看当前分配情况</el-button>
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="submit2">分 配</el-button>

            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
    data() {
        return {
            status:'',
            values:true,
            values1: false,
            values3:1,
            values4:0,
            values5:'',
            value6: 1,
            value7: 2,
            value8: 3,
            loading: false,
            swiperOptions: {
                // loop: true,
                direction: "vertical",
                autoplay: {
                    delay: 2000
                }
            },
            warn: [{}],
            // tableData: [{}],
            // 总数据
            tableData: [],
            tableData1:[],
            // 默认显示第几页
            currentPage: 1,
            // 总条数，根据接口获取数据长度(注意：这里不能为空)
            totalCount: 1,
            // 个数选择器（可修改）
            pageSizes: [12, 20, 30, 40],
            // 默认每页显示的条数（可修改）
            PageSize: 12,
            company: '',
            dialogFormVisible: false,
            dialogFormVisible1: false,
            dialogFormVisible2: false,
            username: '',
            role:'',
            id:'',
            list:'',
            branchCompany:'',
            multipleSelection: [],
            detail:[],
            form: {
                company: '',
                email: '',
                phone: '',
                status: '',
                branchCompany: ''
            },
            form1: {
                username: '',
                password: '',
                company: '',
                email: '',
                phone: '',
                roleId:'',
                role:'',
                branchCompany:''
            },
            rules: {//验证规则
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
            },
            guize:{
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 100, message: '请输入3位以上用户名', trigger: 'blur' }
                ],
                password:[
                { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 5, max: 100, message: '请输入5位以上密码', trigger: 'blur' }
                ],
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                role: [
                    { required: true, message: '请选择用户级别', trigger: 'blur' },
                ],
                branchCompany: [
                    { required: true, message: '请输入分店名称', trigger: 'blur' },
                ],
            }


        }
    },
    created() {
    },
    computed: {
        ...mapState({ loginModule: state => state.loginModule.userinfo }),
    },
    mounted() {
        this.getlist()
        // this.getwarn()
        // this.timer = setInterval(() => {
        //     this.getwarn();
        // }, 10000);
    },
    methods: {
        reset(row){
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if(user_role === '1'){
            this.$confirm('此操作将密码重置为1qaz!2wsx, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    this.axios({
                url:'https://www.anbisibiz.cn/sys/user/reset/pass?id='+row.id,
                method: 'post',
                headers: {
                    ['access-token']: jwt
                }
            }).then((res=>{
                // console.log(res)
                if(res.data.status == 200){
                this.$message.success('重置密码成功')
                this.getlist()
                }else{
                    this.$message.error('重置密码失败')
                this.getlist()
                }
            })).catch((err)=>{
                this.$message.error('重置密码失败')
            })
                })
            }else{
                this.$message.error('您没有操作权限！')
            }
        },
        handleDelete(row){
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if(user_role === '1'){
            this.$confirm('此操作将删除用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(()=>{
                    this.axios({
                url:'https://www.anbisibiz.cn/sys/user/delete?id='+row.id,
                method: 'post',
                headers: {
                    ['access-token']: jwt
                }
            }).then((res=>{
                // console.log(res)
                if(res.data.status == 200){
                this.$message.success('删除用户成功')
                this.getlist()
                }else{
                    this.$message.error('删除用户失败')
                this.getlist()
                }
            })).catch((err)=>{
                this.$message.error('删除用户失败')
            })
                })
            }else{
                this.$message.error('您没有操作权限！')
            }

        },
        toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row,true);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
        add() {
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if(user_role === '1'){
            this.dialogFormVisible1 = true
        }else{
            this.$message.error('您没有操作权限！')
        }
        },
        allot(row){
            // console.log(row)
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            this.id = row.id
            if(user_role === '1'){
            this.axios({
                url:'https://www.anbisibiz.cn/sys/user/device/list?id=' + row.id,
                method: 'post',
                headers: {
                    ['access-token']: jwt
                },
            }).then((res=>{
                // console.log(res)
                this.tableData1 = res.data.data

                    this.dialogFormVisible2 = true
                // console.log(this.tableData1[this.tableData1.length-1].remark)
                // console.log(this.tableData1.remark)

                // if(this.tableData1.length){
                //     this.$nextTick(function(){
                //         this.tableData1.forEach(item=>{
                //             console.log(item.remark)
                //             if(item.remark == 1){
                //                 this.$refs.multipleTable.toggleRowSelection(row,true)
                //             }
                //         })
                //     })
                // }
                

                // this.$refs.multipleSelection.toggleRowSelection(row,true)


                this.list = this.tableData1.filter(function(item,index,array){
                    return item.remark > 0;
                })
                // console.log(this.list)
                if(this.list){
                    this.list.forEach((row) =>{
                        this.$refs.multipleTable.toggleRowSelection(row,true)
                    })
                }
                // this.toggleSelection(this.list);
            })).catch((err)=>{
            })
            }else{
                this.$message.error('您没有操作权限！')
            }
        },
        // submit(){
        //     let jwt = this.loginModule.token
        //     this.axios({
        //         url:'https://www.anbisibiz.cn/sys/user/save',
        //         method: 'post',
        //         headers: {
        //             ['access-token']: jwt
        //         },
        //         data: {
        //             ...this.form1
        //         }
        //     }).then((res=>{
        //         // console.log(res)
        //         this.dialogFormVisible1 = false
        //         if(res.data.status == 200){
        //         this.$message.success('添加用户成功')
        //         this.getlist()
        //         }else{
        //             this.$message.error('添加用户失败')
        //             this.getlist()
        //         }
        //     })).catch((err)=>{
        //         this.dialogFormVisible1 = false
        //         this.$message.error('添加用户失败')
        //     })
        // },
        submit1(){
            let jwt = this.loginModule.token
            this.axios({
                url:'https://www.anbisibiz.cn/sys/user/save',
                method: 'post',
                headers: {
                    ['access-token']: jwt
                },
                data: {
                    ...this.form1
                }
            }).then((res=>{
                // console.log(res)
                this.dialogFormVisible1 = false
                if(res.data.status == 200){
                this.$message.success('添加用户成功')
                this.getlist()
                }else{
                    this.$message.error('添加用户失败')
                    this.getlist()
                }
            })).catch((err)=>{
                this.dialogFormVisible1 = false
                this.$message.error('添加用户失败')
            })
        },
        submit() {
            let jwt = this.loginModule.token
            // console.log(this.form)
            this.axios({
                url: 'https://www.anbisibiz.cn/sys/user/update',
                method: 'post',
                headers: {
                    ['access-token']: jwt
                },
                data: {
                    ...this.form
                }
            }).then((res => {
                // console.log(res)
                // console.log(res)
                this.dialogFormVisible = false
                if(res.data.status == 200){
                this.$message.success('修改成功')
                this.getlist()
                }else{
                    this.$message.error('修改失败')
                    this.getlist()
                }
            })).catch((err)=>{
                this.$message.error('修改失败')
            })
        },
        submit2(){
            console.log(this.id,1111)
            let jwt = this.loginModule.token
            this.axios({
                url:'https://www.anbisibiz.cn/sys/user/device/allow',
                method:'post',
                headers: {
                    ['access-token']: jwt
                },
                data:{
                    userId:this.id,
                    deviceIds: this.detail
                }
            }).then((res=>{
                console.log(res)
                if(res.data.status == 200){
                this.$message.success('修改成功')
                this.dialogFormVisible2 = false
                this.getlist()
                }else{
                    this.$message.error('修改失败')
                    this.dialogFormVisible2 = false
                    this.getlist()
                }
            })).catch((err=>{
                this.$message.error('修改失败')
                this.dialogFormVisible2 = false
            }))
        },
        updata(row) {
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if(user_role === '1'){
            this.dialogFormVisible = true
            // if(row.status == true){
            //     this.form.status = '正常用户'
            // }else{
            //     this.form.status = '黑名单用户' 
            // }
            this.form = row
            if(row.role === '超级管理员'){
                this.form.role = 1
            }else if (row.role === '二级用户'){
                this.form.role = 2
            }else if (row.role === '三级用户'){
                this.form.role = 3
            }
        }else{
            this.$message.error('您没有操作权限！')
        }
            
            // console.log(this.form)
        },
        query() {
            this.getlist()
            this.currentPage = 1
        },
        getlist() {
            let jwt = this.loginModule.token
            this.loading = true
            this.axios({
                url: "https://www.anbisibiz.cn/sys/user/query",
                method: "post",
                headers: {
                    ['access-token']: jwt
                },
                data:{
                    usernam: this.username,
                    company:this.company,
                    status: this.status,
                    role:this.role,
                    pageIndex: this.currentPage,
                    pageSize: this.PageSize,
                    branchCompany: this.branchCompany
                }
            }).then((res => {
                // console.log(11111111111, res.data.data)
                this.tableData = res.data.data.result
                this.totalCount = res.data.data.total
                this.loading = false
            }))
        },
        handleSizeChange(val) {
            // 改变每页显示的条数 
            this.PageSize = val
            // 注意：在改变每页显示的条数时，要将页码显示到第一页
            this.currentPage = 1
            this.getlist()
        },
        // 显示第几页
        handleCurrentChange(val) {
            // 改变默认的页数
            this.currentPage = val
            this.getlist()
        },
        handleSelectionChange(val) {
        this.multipleSelection = val;
        this.detail = this.multipleSelection.map(item=>{
            return(item.id)
        });

        
        // console.log(val,11111111)
        // console.log(3333,this.multipleSelection)
        // console.log(22222,this.detail)
      }
    },
    components: {}
}
</script>

<style lang="scss" scoped>
.box {
    display: flex;
}

.top {
    background: red;
    width: 100%;
    height: 30px;
    margin: 10px auto;
}

.swiper-container {
    width: 100%;
    height: 30px;
    border: 1px solid #007acc;
    text-align: center;


    span {
        line-height: 30px;
    }
}

.common-tabel {
    position: relative;
    height: 800px;

    .pager {
        position: absolute;
        bottom: 0;
        right: 20px;
    }
}

/deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
}

// /deep/ .el-table__row{
//   :hover{
//     background-color: #000000;
//   }
// }
/deep/ td {
    padding: 0px;
    // background: #f5f5f5;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
}

/deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
}
</style>
