<template>
  <div class="box" style="width: 100%;">
    <div class="box1">
      <div style="width: 384px;">
        <span style="font-size: 20px;font-weight:bolder">设备列表</span>
        <div class="left-bottom" style="margin-top: 20px;">
          <div class="manage-header" style="width:372px;padding-top: 10px; margin-left: auto;margin-right: auto;">
            <!-- <el-button @click="handleAdd" type="primary">
                + 新增
            </el-button> -->
            <!-- form搜索区域 -->
            <el-form :inline="true" :model="userForm">
              <el-form-item>
                <div class="item1">
                  <el-input placeholder="请输入查询内容" style="width:278px;height:42px;background: #f2f2f2;"></el-input>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit" style="width:73px;height:42px">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data="tableData" @row-click="rowClick" :disabled="false" style="width: 100%;"
            :header-cell-style="{ width: '100%', fontsize: '16px', color: 'black' }">
            <el-table-column prop="device_id" label="设备编号">
            </el-table-column>
            <el-table-column prop="name" label="所属单位">
            </el-table-column>
            <el-table-column prop="address" label="所在地点">
            </el-table-column>
            <el-table-column prop="" label="设备状态">
              <template slot-scope="scope">
                <span>{{ scope.row.device_status == 'offline' ? '离线' : '在线' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="left">
        <span style="font-size: 20px;font-weight:bolder">往期数据</span>
        <div style="width: 895px;height:750px;background:#ffffff;margin-top: 20px;"></div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
export default {
  data() {
    return {
      items:[{
          name:'请点击设备',
          data: ''
        }],
        tableData: [
    ]
    }
  },
  created() { },
  computed: {
    ...mapState({ loginModule: state => state.loginModule.userinfo }),
  },
  mounted() { this.getList() },
  methods: {
    getList() {
      // 获取的列表的数据
      //         let vm = this;
      //   let jwt = this.loginModule.token
      //   this.axios.get("https://www.anbisibiz.cn/wxuser?pageIndex=1&pageSize=10",
      //   {
      //     headers:{jwt}
      //   }).then((res=>{
      //     console.log(res)
      //     this.tableData = res.data.data.rows
      //     this.total = res.data.data.total
      //   }))
      let vm = this;
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/list",
        method: "get",
        // contentType:"application/json",
        headers: {
          // 'Content-type': 'application/x-www-form-urlencoded',
          ['access-token']: jwt
        },
      })
        // let data = {
        //     ...this.userForm,
        //     pageIndex: 1,
        //     pageSize: 10
        // }
        // this.$api.getViplist({
        //     jwt
        // })
        .then((res => {
          // console.log(res.data.data)
          // console.log(res)
          if (res.data.data == null) {
            // console.log(123)
          } else {
            // console.log(456)
            this.tableData = res.data.data
          }


        }));
    },
    rowClick(row) {
      let jwt = this.loginModule.token
      // this.axios({
      //   url:"https://www.anbisibiz.cn/device/param?id
      // })
      // console.log(row.device_status)
      // console.log(this.items[0].name)
      if (row.device_status == "offline") {
        this.items = [{
          name: '设备不在线'
        }]
        this.display = 'none'
      } else {
        this.axios.get("https://www.anbisibiz.cn/device/param?id=" + row.device_id, {
          headers: { ['access-token']: jwt }
        }).then((res => {
          // console.log(res.data.data)
          this.display = 'inline-block'
          this.items = res.data.data.device_param
        })).catch((err => {
          // console.log(err)
        }))
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-bottom {
  background-color: #ffffff;
  width: 384px;
  height: 750px;
}

.el-table {

  // 看这里！！！！！！！！！！！！！！！！！！！！！！！！！！！！
  // 深度选择器，去除默认的padding
  /deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
  }

  // /deep/ .el-table__row{
  //   :hover{
  //     background-color: #000000;
  //   }
  // }
  /deep/ tbody tr:hover>td {
    // :hover{
    //   background-color: #000000;
    // }
    cursor: pointer;
    background-color: rebeccapurple !important;
    color: #ffffff;
  }

  /deep/ td {
    padding: 2px;
    background: #f5f5f5;
    font-size: 12px;
    text-align: center;
  }

  /deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
  }
}

/deep/.el-input {
  // 输入框高度
  //  height: 52px;
  // 边框圆角
  border-radius: 10px;
}

/deep/.el-input__inner {
  //  height: 52px;
  background: #f2f2f2;
  // 设置字号
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // 设置输入字体的颜色
  color: #2c2c2c;
}

.box1 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 1565px;
  justify-content: space-around;
}
</style>
