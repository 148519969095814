<template>
    <h1>我是Home</h1>
</template>

<script type="text/ecmascript-6">
import MapContainer from '@/components/MapContainer.vue';
export default {
    data () {
        return {}
    },
    created () { },
    mounted () { },
    methods: {},
    components: { MapContainer }
}
</script>

<style lang="scss" scoped>
.index_container {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: relative;
    .map_wrap{
        height: 60%;
    }
}
</style>
