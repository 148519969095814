import router from './index';
import store from '../store/index'




// router.beforeEach((to,from,next)=>{
//   if(to.matched.some(item=>item.meta.isLogin)){
//     let token =store.state.loginModule.userinfo.token;
//     if(token){
//       next()
//     }else{
//       next('/login')
//     }
//   }else{
//     console.log('不需要登录');
//     next()
//   }
// })



// 全局导航守卫
router.beforeEach((to,from,next)=>{
  //1.判断是否需要登录
  if(to.matched.some(item=>item.meta.isLogin)){//需要登录
    //2. 判断需要登录  是否已经登录
    let token =store.state.loginModule.userinfo.token;
    if(token){
      next()
    }else{
      next('/login')
    }
  }else{
    console.log('不需要登录');
    next()
  }
})

