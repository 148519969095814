<template>
  <div class="box" style="width: 100%">
    <div style="width: 100%; height: 100%">
      <div class="top">
        <swiper ref="myside" :options="swiperOptions" @click="click">
          <swiper-slide v-for="item in warn">
            <span
              >分店名称：{{ item.device_company }} 故障名称：{{
                item.warn_name
              }}
              时间：{{ item.warn_time }}</span
            >
          </swiper-slide>
        </swiper>
      </div>
      <div style="display: flex">
        <div style="width: 480px">
          <span style="font-size: 20px; font-weight: bolder">设备列表</span>
          <div class="left-bottom" style="margin-top: 20px">
            <div
              class="manage-header"
              style="width: 480px; padding-top: 10px; margin-left: 15px"
            >
              <!-- <el-button @click="handleAdd" type="primary">
              + 新增
          </el-button> -->
              <!-- form搜索区域 -->
              <el-form :inline="true">
                <el-form-item>
                  <div class="item1">
                    <el-input
                      placeholder="请输入查询内容"
                      v-model="parm"
                      style="width: 370px; height: 42px; background: #f2f2f2"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button
                    @click="onSubmit"
                    type="primary"
                    style="width: 73px; height: 42px"
                    >搜索</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
            <div style="height: 629px; overflow: auto">
              <el-table
                :data="tableData1"
                @row-click="rowClick"
                :disabled="false"
                style="width: 100%"
                highlight-current-row
                :header-cell-style="{
                  width: '100%',
                  fontsize: '16px',
                  color: 'black',
                }"
              >
                <el-table-column prop="device_id" label="设备编号">
                </el-table-column>
                <el-table-column prop="device_company" label="所属单位">
                </el-table-column>
                <el-table-column prop="device_name" label="分店名称">
                </el-table-column>
                <el-table-column prop="device_position" label="所在地点">
                </el-table-column>
                <el-table-column prop="" label="设备状态">
                  <template slot-scope="scope" style="align-items: center">
                    <img
                      v-if="scope.row.device_status == 'offline'"
                      src="../assets/images/status1.png"
                      style="height: 12px; padding-right: 10px"
                    />
                    <img
                      v-else="scope.row.device_status == 'offline'"
                      src="../assets/images/status.png"
                      style="height: 12px; padding-right: 10px"
                    />
                    <span>{{
                      scope.row.device_status == "offline" ? "离线" : "在线"
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div style="width: 90%; margin-left: 20px">
          <span style="font-size: 20px; font-weight: bolder"
            >隔油日清洗往期数据</span
          >
          <div class="block" style="display: flex; margin-top: 20px">
            <el-form :inline="true">
              <el-form-item>
                <div class="item1">
                  <!-- <el-input placeholder="请输入公司名称" v-model="company" style="margin-left:10px;width:150px;height:42px;background: #f2f2f2;"></el-input>
                <el-input placeholder="请输入设备编号" v-model="id" style="margin-left:10px;width:150px;height:42px;background: #f2f2f2;"></el-input> -->
                  <!-- <el-input placeholder="请输入设备类型" v-model="type" style="margin-left:10px;width:150px;height:42px;background: #f2f2f2;"></el-input> -->
                </div>
              </el-form-item>
            </el-form>
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-button
              @click="query"
              type="primary"
              style="
                width: 100px;
                height: 42px;
                font-size: 16px;
                margin-left: 10px;
              "
              >查询</el-button
            >
          </div>
          <div class="common-tabel" style="margin-top: 10px; padding-top: 10px">
            <el-table
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.2)"
              stripe
              height="90%"
              :data="tableData"
              style="width: 100%"
              :cell-style="{ 'text-align': 'center' }"
            >
              <el-table-column
                align="center"
                prop="deviceId"
                label="设备序列号"
              >
              </el-table-column>
              <!-- <el-table-column align="center" prop="device_company" label="所属单位">
                      </el-table-column> -->
              <el-table-column align="center" prop="cleanName" label="数据名称">
              </el-table-column>
              <el-table-column align="center" prop="status" label="设备状态">
              </el-table-column>
              <el-table-column align="center" prop="time" label="数据时间">
              </el-table-column>
            </el-table>
            <div class="pager">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="PageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
      return {
          swiperOptions: {
              // loop: true,
              direction: "vertical",
              autoplay: {
                  delay: 2000
              }
          },
          warn: [{}],
          // tableData: [{}],
          // 总数据
          tableData: [],
          tableData1:[],
          // 默认显示第几页
          currentPage: 1,
          // 总条数，根据接口获取数据长度(注意：这里不能为空)
          totalCount: 1,
          // 个数选择器（可修改）
          pageSizes: [18, 24, 36, 48],
          // 默认每页显示的条数（可修改）
          PageSize: 18,
          value1: [new Date(2023, 0, 1, 0, 0 , 0,),new Date(2033, 0, 1, 23, 59 , 59,)],
          company:'',
          id:'',
          type:'',
          parm: '',
          loading: false
      }
  },
  created() {
      this.getNowTime()
  },
  computed: {
      ...mapState({ loginModule: state => state.loginModule.userinfo }),
  },
  mounted() {
      this.getList1()
      this.getwarn()
      // this.getlist()
      this.timer = setInterval(() => {
          this.getwarn();
      }, 10000);
  },
  methods: {
      getNowTime() {
       var now = new Date();
       var year = now.getFullYear(); //得到年份
       var month = now.getMonth(); //得到月份
       var date = now.getDate(); //得到日期
       var hour = now.getHours(); //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
         var minute = now.getMinutes();
         var second = now.getSeconds()
       month = month.toString().padStart(2, "0");
       date = date.toString().padStart(2, "0");
    //    this.date = new Date(`${year},${month},${date},${hour},${minute},${second}`);
          let time = new Date(year,month,date,hour,minute,second)
          this.value1[1] = time
      //    console.log(this.date)
      //    console.log(this.value1)
    },
      query() {
          this.getlist()
          this.currentPage = 1
      },
      getwarn() {
          let jwt = this.loginModule.token
          this.axios({
              url: "https://www.anbisibiz.cn/device/warn",
              method: "get",
              headers: {
                  ['access-token']: jwt
              },
          }).then((res => {
              // console.log(1111, res.data.data)
              this.warn = res.data.data
              // console.log(12121, this.warn)
          })).catch((err => {
              // console.log(err)
          }))
      },
      getlist() {
          let jwt = this.loginModule.token
          this.loading = true
          let value = this.$moment(this.value1[0]).format('YYYY-MM-DD HH:mm:ss')
          let value1 = this.$moment(this.value1[1]).format('YYYY-MM-DD HH:mm:ss')
          let id = this.id
          let type = this.type
          // console.log(value)
          this.axios({
              url: "https://www.anbisibiz.cn/clean/list?&deviceImei=" + id +  "&startTime=" + value + "&endTime=" + value1 + '&pageIndex=' + this.currentPage + '&pageSize=' + this.PageSize,
              method: "post",
              headers: {
                  ['access-token']: jwt
              },
              // data:{
              //     startTime: value
              // }
          }).then((res => {
            this.loading = false
              this.tableData = res.data.data.result
              this.totalCount = res.data.data.total
          }))
      },
      rowClick(row) {
    let jwt = this.loginModule.token
    // this.axios({
    //   url:"https://www.anbisibiz.cn/device/param?id
    // })
    this.refresh = row
    this.id = row.device_id
    // console.log(row.device_status)
    // console.log(this.items[0].name)
      this.getlist()
      .then((res => {
        console.log(res)
        this.display = 'inline-block'
        this.items = res.data.data.device_param
      })).catch((err => {
        // console.log(err)
      }))
  },
      getList1() {
    // 获取的列表的数据
    //         let vm = this;
    //   let jwt = this.loginModule.token
    //   this.axios.get("https://www.anbisibiz.cn/wxuser?pageIndex=1&pageSize=10",
    //   {
    //     headers:{jwt}
    //   }).then((res=>{
    //     console.log(res)
    //     this.tableData = res.data.data.rows
    //     this.total = res.data.data.total
    //   }))
    let vm = this;
    let jwt = this.loginModule.token
    this.axios({
      url: "https://www.anbisibiz.cn/device/list",
      method: "get",
      // contentType:"application/json",
      headers: {
        // 'Content-type': 'application/x-www-form-urlencoded',
        ['access-token']: jwt
      },
    })
      // let data = {
      //     ...this.userForm,
      //     pageIndex: 1,
      //     pageSize: 10
      // }
      // this.$api.getViplist({
      //     jwt
      // })
      .then((res => {
        // console.log(res.data.data)
        // console.log(res)
        if (res.data.data == null) {
        } else {
          this.tableData1 = res.data.data
        }


      }));
  },
      handleSizeChange(val) {
          // 改变每页显示的条数
          this.PageSize = val
          // 注意：在改变每页显示的条数时，要将页码显示到第一页
          this.currentPage = 1
          this.getlist()
      },
      // 显示第几页
      handleCurrentChange(val) {
          // 改变默认的页数
          let jwt = this.loginModule.token
          this.currentPage = val
          this.getlist()
          // console.log(11111,this.tableData)
      },

      click() {
    this.$router.push('/warn');
  },
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.left-bottom {
  background-color: #ffffff;
  width: 480px;
  height: 750px;
}
.box {
  display: flex;
}

.top {
  background: red;
  width: 100%;
  height: 30px;
  margin: 10px auto;
}

.swiper-container {
  width: 100%;
  height: 30px;
  border: 1px solid #007acc;
  text-align: center;
  :hover {
    cursor: pointer;
  }
  span {
    line-height: 30px;
  }
}
.common-tabel {
  position: relative;
  height: 800px;

  .pager {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
}

/deep/ th {
  padding: 5px;
  font-size: 14px;
  padding-right: 3px;
}

// /deep/ .el-table__row{
//   :hover{
//     background-color: #000000;
//   }
// }
/deep/ td {
  padding: 0px;
  // background: #f5f5f5;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

/deep/ .el-table__body {
  border-collapse: separate;
  border-spacing: 0 8px;
  table-layout: auto !important;
}

.el-table {
  // 看这里！！！！！！！！！！！！！！！！！！！！！！！！！！！！
  // 深度选择器，去除默认的padding
  /deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
  }

  // /deep/ .el-table__row{
  //   :hover{
  //     background-color: #000000;
  //   }
  // }
  /deep/ tbody tr:hover > td {
    // :hover{
    //   background-color: #000000;
    // }
    cursor: pointer;
    background-color: rebeccapurple !important;
    color: #ffffff;
  }

  /deep/ td {
    padding: 2px;
    background: #f5f5f5;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
  }

  /deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
  }
}

/deep/.el-input {
  // 输入框高度
  //  height: 52px;
  // 边框圆角
  border-radius: 10px;
}

/deep/.el-input__inner {
  //  height: 52px;
  background: #f2f2f2;
  // 设置字号
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // 设置输入字体的颜色
  color: #2c2c2c;
}
</style>
<style lang="scss">
.current-row {
  td {
    background-color: rebeccapurple !important;
    color: #ffffff;
  }
}
</style>
