<template>
  <div class="box">
    <div style="width: 100%;">
      <div class="top">
        <swiper ref="myside" :options="swiperOptions" @click="click">
          <swiper-slide v-for="item in warn">
            <span>分店名称：{{ item.device_company }} 故障名称：{{ item.warn_name }} 时间：{{ item.warn_time }}</span>
          </swiper-slide>
        </swiper>
      </div>
      <div class="box1">
        <div style="width: 480px;">
          <span style="font-size: 20px;font-weight:bolder">设备列表</span>
          <div class="left-bottom" style="margin-top: 20px;">
            <div class="manage-header" style="width:100%;padding-top: 10px; margin-left: 15px">
              <!-- <el-button @click="handleAdd" type="primary">
                + 新增
            </el-button> -->
              <!-- form搜索区域 -->
              <el-form :inline="true">
                <el-form-item>
                  <div class="item1">
                    <el-input placeholder="请输入查询内容" v-model="parm"
                      style="width:370px;height:42px;background: #f2f2f2;color: aqua;"></el-input>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit" style="width:73px;height:42px">搜索</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div style="height: 629px;overflow:auto">
              <el-table :data="tableData" @row-click="rowClick" :disabled="false" style="width: 100%;"
                highlight-current-row :header-cell-style="{ width: '100%', fontsize: '16px', color: 'black' }">
                <el-table-column prop="device_id" label="设备编号">
                </el-table-column>
                <el-table-column prop="device_company" label="所属单位">
                </el-table-column>
                <el-table-column prop="device_name" label="分店名称">
                </el-table-column>
                <el-table-column prop="device_position" label="所在地点">
                </el-table-column>
                <el-table-column prop="" label="设备状态">
                  <template slot-scope="scope">
                    <img v-if="scope.row.device_status == 'offline'" src="../assets/images/status1.png"
                      style="height: 12px;padding-right: 10px;" />
                    <img v-else="scope.row.device_status == 'offline'" src="../assets/images/status.png"
                      style="height: 12px;padding-right: 10px;" />
                    <span>{{ scope.row.device_status == 'offline' ? '离线' : '在线' }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="left" style="width: 90%;margin-left: 20px;">
          <div style="display: flex; justify-content: space-between;align-items: center; ">
            <span style="font-size: 20px;font-weight:bolder">实时数据面板</span>
            <el-button type="mini" @click="refresh1" style="text-align: center;background-color: #66b1ff;color: #ffffff;"
              :disabled="flag">刷新</el-button>
          </div>
          <div v-if="status == ''" style="width: 100%;height:410px;background:#ffffff;margin-top: 20px;">
            <span>请点击设备</span>
          </div>
          <div v-else-if="status == 'offline'" style="width: 100%;height:410px;background:#ffffff;margin-top: 20px;">
            设备不在线
          </div>
          <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)" v-else="status == 'online'"
            style="min-width:900px;width: 100%;height:410px;background:#ffffff;margin-top: 20px;">
            <div>
              <div style="display: flex;justify-content: space-around;padding-top: 10px;">
                <div>安必思 硬件版本: {{ data1 }}({{ data2 }})软件版本：{{ data3 }}({{ data4 }})</div>
                <div>{{ data5 }}</div>
              </div>
              <div class="data-box">
                <div>推进：{{ data6 }}</div>
                <div>加热：{{ data7 }}</div>
                <div>喷淋阀： {{ data8 }}</div>
                <div>油桶检测： {{ data9 }}</div>
                <div>排淤阀1： {{ data10 }}</div>
                <div>抽油泵： {{ data11 }}</div>
                <div>故障状态： {{ data12 }}</div>
                <div>推进1电流：{{ data13 }}</div>
                <div>挤压电流：{{ data14 }}</div>
                <div>抽油泵电流： {{ data15 }}</div>
                <div>温度： {{ data16 }}</div>
                <div>油桶重量: {{ data17 }}</div>
                <div>排淤阀： {{ data18 }}</div>
                <div>油感器： {{ data19 }}</div>
                <div>感应开关： {{ data20 }}</div>
                <div>水流开关： {{ data21 }}</div>
                <div>水位开关： {{ data22 }}</div>
                <div>渣桶检测感应： {{ data24 }}</div>
                <div>急停： {{ data25 }}</div>
                <div>停止倒计时：{{ data26 }}</div>
                <div>进料阀： {{ data27 }}</div>
                <div>隔油工作模式： {{ data28 }}</div>
                <div>隔油日清洗状态： {{ data29 }}</div>
                <div>挤压：{{ data30 }}</div>
                <div>总累计出油量：{{ data31 }}</div>
                <div>日累计出油量：{{ data32 }}</div>
                <div>月累计出油量：{{ data33 }}</div>
                <div>年累计出油量：{{ data34 }}</div>
                <div>日累计次数： {{ data35 }}</div>
                <div>总累计次数： {{ data36 }}</div>
                <div>调压最小： {{ data37 }}</div>
                <div>调压最大： {{ data38 }}</div>
                <div>加热电流： {{ data39 }}</div>
                <div>锁定器： {{ data40 }}</div>
                <div>渣桶满桶： {{ data41 }}</div>
                <div>加减压状态： {{ data42 }}</div>
                <div>仓门状态： {{ data43 }}</div>
                <div>锁定电机： {{ data44 }}</div>
                <div>推进过流次数： {{ data45 }}</div>
                <div>挤压过流次数： {{ data46 }}</div>
                <div>过载处理过程： {{ data47 }}</div>
                <div>加热2电流：{{ data48 }}</div>
                <div>加热2： {{ data49 }}</div>
                <div>累计运行时间： {{ data50 }}</div>
                <div>温度B: {{ data51 }}</div>
                <div>当前音量： {{ data52 }}</div>
                <div>冲洗阀： {{ data53 }}</div>
                <div style="font-size:8px">推进1正传累计运行时间： {{ data54 }}分</div>
                <div style="fontSize:8px">推进1反转累计运行时间： {{ data55 }}分</div>
                <div style="fontSize:8px">加热累计运行时间： {{ data56 }}分</div>
                <div style="fontSize:8px">挤压正传累计运行时间： {{ data57 }}分</div>
                <div style="fontSize:8px">挤压反转累计运行时间： {{ data58 }}分</div>
                <div style="fontSize:8px">加热2累计运行时间： {{ data59 }}分</div>

                <div style="fontSize:8px">*挤压累计运行时间： {{ data61 }}分</div>
                <div style="fontSize:8px">*加热累计运行时间： {{ data62 }}分</div>
                <div style="fontSize:8px">*加热2累计运行时间： {{ data63 }}分</div>
                <div style="fontSize:8px">*喷淋阀累计运行次数： {{ data64 }}</div>
                <div style="fontSize:8px">*冲洗阀累计运行次数： {{ data65 }}</div>
                <div style="fontSize:8px">*进料阀累计运行次数： {{ data66 }}</div>
                <div style="fontSize:8px">*抽油泵累计运行次数： {{ data67 }}</div>
                <div style="fontSize:8px">*锁定器累计运行次数： {{ data68 }}</div>
                <div style="fontSize:8px">*排淤阀累计运行次数： {{ data69 }}</div>

              </div>
            </div>
          </div>
          <span style="font-size: 20px;font-weight:bolder">走势图</span>
          <div v-if="status == ''">
            <span>请点击设备</span>
          </div>
          <div v-else-if="status == 'offline'">
            设备不在线
          </div>
          <div v-loading="loading1" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)" v-else="status == 'online'">
            <e-charts class="chart" :option="option" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
    return {
      swiperOptions: {
        // loop: true,
        direction: "vertical",
        autoplay: {
          delay: 2000
        }
      },
      items: [{
        name: '请点击设备',
        data: ''
      }],
      warn: [{}],
      tableData: [
      ],
      charts: [],
      charts1: [],
      loading: true,
      loading1: true,
      parm: "",
      data1: '',
      data2: '',
      data3: '',
      data4: '',
      data5: '',
      data6: '',
      data7: '',
      data8: '',
      data9: '',
      data10: '',
      data11: '',
      data12: '',
      data13: '',
      data14: '',
      data15: '',
      data16: '',
      data17: '',
      data18: '',
      data19: '',
      data20: '',
      data21: '',
      data22: '',
      data24: '',
      data25: '',
      data26: '',
      data27: '',
      data28: '',
      data29: '',
      data30: '',
      data31: '',
      data32: '',
      data33: '',
      data34: '',
      data35: '',
      data36: '',
      data37: '',
      data38: '',
      data39: '',
      data40: '',
      data41: '',
      data42: '',
      data43: '',
      data44: '',
      data45: '',
      data46: '',
      data47: '',
      data48: '',
      data49: '',
      data50: '',
      data51: '',
      data52: '',
      data53: '',
      data54: '',
      data55: '',
      data56: '',
      data57: '',
      data58: '',
      data59: '',
      data60: '',
      data61: '',
      data62: '',
      data63: '',
      data64: '',
      data65: '',
      data66: '',
      data67: '',
      data68: '',
      data69: '',

      refresh: '',
      status: '',
      flag: false,

    }
  },
  created() { },
  computed: {
    ...mapState({ loginModule: state => state.loginModule.userinfo }),
    option() {
      let that = this
      return {
        //       title: {
        // },
        // tooltip: {
        //   trigger: 'axis'
        // },
        // legend: {
        //   data: ['温度', '温度B']
        // },
        // grid: {
        //   left: '3%',
        //   right: '4%',
        //   bottom: '3%',
        //   containLabel: true
        // },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        // xAxis: {
        //   type: 'category',
        //   boundaryGap: false,
        //   data: that.charts.map((d) => d.time)
        // },
        // yAxis: {
        //   type: 'value'
        // },
        // series: [
        //   {
        //     name: '温度',
        //     type: 'line',
        //     stack: 'Total',
        //     data: that.charts.map((d) => d.value),
        //     showSymbol: false
        //   },
        //   {
        //     name: '温度B',
        //     type: 'line',
        //     stack: 'Total',
        //     data: that.charts1.map((d) => d.value),
        //     showSymbol: false

        //   },
        // ]
        title: {
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: { data: ['温度', '温度B'] },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.charts.map((d) => d.time)
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} °C'
          }
        },
        series: [
          {
            name: '温度',
            type: 'line',
            data: that.charts.map((d) => d.value),
            showSymbol: false
          },
          {
            name: '温度B',
            type: 'line',
            data: that.charts1.map((d) => d.value),
            showSymbol: false
          }
        ]
      }
    }
  },
  mounted() {
    this.getList()
    this.getwarn()
  },
  methods: {
    click() {
      this.$router.push('/warn');
    },
    onSubmit() {
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/query?condition=" + this.parm,
        method: 'get',
        headers: {
          ['access-token']: jwt
        },
      }).then((res => {
        // console.log(res.data.data)
        // console.log(res)
        if (res.data.data == null) {
        } else {
          this.tableData = res.data.data
        }


      }))
    },
    getwarn() {
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/warn",
        method: "get",
        headers: {
          ['access-token']: jwt
        },
      }).then((res => {
        // console.log(1111, res.data.data)
        this.warn = res.data.data
        // console.log(12121, this.warn)
      })).catch((err => {
        // console.log(err)
      }))
    },
    refresh1() {
      let jwt = this.loginModule.token
      let row = this.refresh
      this.flag = true
      // 执行耗时间的代码或事件请求等....
      // console.log(row,111)
      if (row == '') {

      } else {
        this.rowClick(row)
      }
      // 等待事件执行完或者相应的间隔时间后，恢复按钮为可点击状态
      setTimeout(() => {
        this.flag = false
      }, 1000)
    },
    getList() {
      // 获取的列表的数据
      //         let vm = this;
      //   let jwt = this.loginModule.token
      //   this.axios.get("http://39.105.122.19:8878/wxuser?pageIndex=1&pageSize=10",
      //   {
      //     headers:{jwt}
      //   }).then((res=>{
      //     console.log(res)
      //     this.tableData = res.data.data.rows
      //     this.total = res.data.data.total
      //   }))
      let vm = this;
      let jwt = this.loginModule.token
      this.axios({
        url: "https://www.anbisibiz.cn/device/list",
        method: "get",
        // contentType:"application/json",
        headers: {
          // 'Content-type': 'application/x-www-form-urlencoded',
          ['access-token']: jwt
        },
      })
        // let data = {
        //     ...this.userForm,
        //     pageIndex: 1,
        //     pageSize: 10
        // }
        // this.$api.getViplist({
        //     jwt
        // })
        .then((res => {
          // console.log(res.data.data)
          // console.log(res)
          if (res.data.data == null) {
            // console.log(123)
          } else {
            // console.log(456)
            this.tableData = res.data.data
          }


        }));
    },
    rowClick(row) {
      this.loading = true
      this.loading1 - true
      let jwt = this.loginModule.token
      // this.axios({
      //   url:"39.105.122.19/device/param?id
      // })
      this.status = row.device_status
      // console.log(row.device_status)
      // console.log(this.items[0].name)
      this.refresh = row
      if (row.device_status == "offline") {
        this.items = ['']
      } else {
        this.axios.get("https://www.anbisibiz.cn/tempers?id=" + row.device_id + "&day=1", {
          headers: { ['access-token']: jwt }
        }).then((res => {
          // console.log(res,1111111111111111111111)
          this.charts = res.data.data.temper
          this.charts1 = res.data.data.temperb
          // console.log(this.charts.map((d) => d.time))
          this.loading1 = false
        }))
        this.axios.get("https://www.anbisibiz.cn/device/current?id=" + row.device_id, {
          headers: { ['access-token']: jwt }
        }).then((res => {
          let items = res.data.data.device_current
          this.loading = false
          console.log(res)
          this.data1 = items[0].data
          this.data2 = items[2].data
          this.data3 = items[1].data
          this.data4 = items[3].data
          this.data5 = '20' + items[4].data + '-' + items[5].data + '-' + items[6].data + '  ' + items[7].data + ':' + items[8].data + ':' + items[9].data
          if (items[10].data == '0' && items[11].data == '0') {
            this.data6 = '停止'
          } else if (items[10].data == '1' && items[11].data == '0') {
            this.data6 = '正转'
          } else if (items[10].data == '0' && items[11].data == '1') {
            this.data6 = '反转'
          };

          if (items[12].data == '0') {
            this.data7 = '关'
          } else if (items[12].data == '1') {
            this.data7 = '开'
          };
          if (items[13].data == '0') {
            this.data8 = '关'
          } else if (items[13].data == '1') {
            this.data8 = '开'
          };
          if (items[14].data == '0') {
            this.data9 = '关'
          } else if (items[14].data == '1') {
            this.data9 = '开'
          };
          if (items[15].data == '0') {
            this.data10 = '关'
          } else if (items[15].data == '1') {
            this.data10 = '开'
          };
          if (items[16].data == '0') {
            this.data11 = '关'
          } else if (items[16].data == '1') {
            this.data11 = '开'
          };
          if (items[17].data == '0') {
            this.data12 = '关'
          } else if (items[17].data == '1') {
            this.data12 = '开'
          };
          this.data13 = items[18].data
          this.data14 = items[19].data
          this.data15 = items[20].data
          this.data16 = items[21].data
          this.data17 = items[22].data
          if (items[23].data == '0' && items[24].data == '0') {
            this.data18 = '控制中'
          } else if (items[23].data == '1' && items[24].data == '0') {
            this.data18 = '关'
          } else if (items[23].data == '0' && items[24].data == '1') {
            this.data18 = '开'
          };
          if (items[25].data == '0') {
            this.data19 = '关'
          } else if (items[25].data == '1') {
            this.data19 = '开'
          };


          if (items[26].data == '0') {
            this.data20 = '关'
          } else if (items[26].data == '1') {
            this.data20 = '开'
          };
          if (items[27].data == '0') {
            this.data21 = '关'
          } else if (items[27].data == '1') {
            this.data21 = '开'
          };
          if (items[28].data == '0') {
            this.data22 == '关'
          } else if (items[28].data == '1') {
            this.data22 = '开'
          };
          if (items[29].data == '0') {
            this.data24 = '关'
          } else if (items[29].data == '1') {
            this.data24 = '开'
          };
          if (items[30].data == '0') {
            this.data25 = '关'
          } else if (items[30].data == '1') {
            this.data25 = '开'
          };

          this.data26 = items[31].data + ':' + items[32].data

          if (items[33].data == '0') {
            this.data27 = '关'
          } else if (items[32].data == '1') {
            this.data27 = '开'
          };

          if (items[34].data == '1') {
            this.data28 = '待机'
          } else if (items[34].data == '2') {
            this.data28 = '自动运行'
          } else if (items[34].data == '3') {
            this.data28 = '清洗'
          } else if (items[34].data == '4') {
            this.data28 = '手动'
          };

          if (items[35].data == '1') {
            this.data29 = '正在清洗'
          } else if (items[35].data == '2') {
            this.data29 = '已清洗'
          } else if (items[35].data == '3') {
            this.data29 = '未清洗'
          };

          if (items[36].data == '0' && items[37].data == '0') {
            this.data30 = '停止'
          } else if (items[36].data == '1' && items[37].data == '0') {
            this.data30 = '正转'
          } else if (items[36].data == '0' && items[37].data == '1') {
            this.data30 = '反转'
          };

          this.data31 = items[38].data
          this.data32 = items[39].data
          this.data33 = items[40].data
          this.data34 = items[41].data
          this.data35 = items[42].data
          this.data36 = items[43].data
          this.data37 = items[44].data
          this.data38 = items[45].data
          this.data39 = items[46].data

          if (items[47].data == '0') {
            this.data40 = '解锁'
          } else if (items[47].data == '1') {
            this.data40 = '锁定'
          };

          if (items[48].data == '0') {
            this.data41 = '未满'
          } else if (items[47].data == '1') {
            this.data41 = '已满'
          };

          if (items[49].data == '0') {
            this.data42 = '停止'
          } else if (items[49].data == '1') {
            this.data42 = '已加压'
          } else if (items[49].data == '2') {
            this.data42 = '已减压'
          } else if (items[49].data == '3') {
            this.data42 = '加压中'
          } else if (items[49].data == '4') {
            this.data42 = '减压中'
          };

          if (items[50].data == '0') {
            this.data43 = '关'
          } else if (items[50].data == '1') {
            this.data43 = '开'
          };

          if (items[51].data == '0' && items[52].data == '0') {
            this.data44 = '停止'
          } else if (items[51].data == '1' && item[52].data == '0') {
            this.data44 = '正转'
          } else if (items[51].data == '0' && items[52].data == '1') {
            this.data44 = '反转'
          };

          this.data45 = items[53].data
          this.data46 = items[54].data
          this.data47 = items[55].data
          this.data48 = items[56].data

          if (items[57].data == '0') {
            this.data49 = '关'
          } else if (items[57].data == '1') {
            this.data49 = '开'
          };

          this.data50 = items[58].data
          this.data51 = items[59].data
          this.data52 = items[60].data

          if (items[61].data == '0') {
            this.data53 = '关'
          } else if (items[61].data == '1') {
            this.data53 = '开'
          };
          this.data54 = items[62].data
          this.data55 = items[63].data
          this.data56 = items[64].data
          this.data57 = items[65].data
          this.data58 = items[66].data
          this.data59 = items[67].data
          this.data60 = items[68].data
          this.data61 = items[69].data
          this.data62 = items[70].data
          this.data63 = items[71].data
          this.data64 = items[72].data
          this.data65 = items[73].data
          this.data66 = items[74].data
          this.data67 = items[75].data
          this.data68 = items[76].data
          this.data69 = items[77].data

          // console.log(res)
        })).catch((err => {
          // console.log(err)
        }))

      }
    },

  },
  components: {}
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-bottom {
  background-color: #ffffff;
  width: 480px;
  height: 750px;
}

.el-table {

  // 看这里！！！！！！！！！！！！！！！！！！！！！！！！！！！！
  // 深度选择器，去除默认的padding
  /deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
  }

  // /deep/ .el-table__row{
  //   :hover{
  //     background-color: #000000;
  //   }
  // }
  /deep/ tbody tr:hover>td {
    // :hover{
    //   background-color: #000000;
    // }
    cursor: pointer;
    background-color: rebeccapurple !important;
    color: #ffffff;
  }


  /deep/ td {
    padding: 2px;
    background: #f5f5f5;
    font-size: 12px;
    text-align: center;
  }

  /deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
  }
}

/deep/.el-input {
  // 输入框高度
  //  height: 52px;
  // 边框圆角
  border-radius: 10px;
}

/deep/.el-input__inner {
  //  height: 52px;
  background: #f2f2f2;
  // 设置字号
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // 设置输入字体的颜色
  color: #2c2c2c;
}

.box1 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  min-width: 1565px;
  width: 100%;
  justify-content: space-around;
}

.top {
  background: red;
  width: 100%;
  height: 30px;
  margin: 10px auto;
}

.swiper-container {
  width: 100%;
  height: 30px;
  border: 1px solid #007acc;
  text-align: center;

  :hover {
    cursor: pointer;
  }

  span {
    line-height: 30px;
  }
}

.data-box {
  display: flex;
  height: 370px;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  // width: 90%;
}

.data-box div {
  margin-top: 10px;
  width: 200px;
  background-color: #d6cbe1;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  margin-left: 20px;
}

.chart {
  height: 300px;
  width: 100%;
}
</style>
<style lang="scss">
.current-row {
  td {
    background-color: rebeccapurple !important;
    color: #ffffff;
  }
}
</style>
