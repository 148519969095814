<!-- <template>
    <div id="container">

    </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
window._AMapSecurityCongig = {
    securityJsCode: '3aca48d28810c22dc5d29b19154c05e0'
}
export default{
    data(){
        return {
            map: null,
            marker: null,
        }
    },
    methods:{
    initMap(){
        AMapLoader.load({
            key:"734354f5c50ad9512dbf698a6ec5323d",             // 申请好的Web端开发者Key，首次调用 load 时必填
            version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins:['AMap.Scale','AMap.ToolBar','AMap.HawkEye','AMap.MapType','AMap.Geolocation'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap)=>{
            this.map = new AMap.Map("container",{  //设置地图容器id
                viewMode:"3D",    //是否为3D地图模式
                zoom:10,           //初始化地图级别
                center:[118.032538,36.808724], //初始化地图中心点位置
            }
            
            )
            this.map.addControl(new AMap.Scale())
            this.map.addControl(new AMap.ToolBar())
            this.map.addControl(new AMap.HawkEye())
            this.map.addControl(new AMap.MapType())
            this.map.addControl(new AMap.Geolocation())
            let marker1 = new AMap.Marker({
                position: new AMap.LngLat(118.032538,36.808724),
                title: '海底捞一分店'
            })
            this.map.add(marker1)
        }).catch(e=>{
            console.log(e);
        })
    },
},
mounted(){
    //DOM初始化完成进行地图初始化
    this.initMap();
}
}
</script>
<style  scoped>
    #container{
        padding:0px;
        margin: 0px;
        width: 100%;
        height: 800px;
    }
</style> -->


<template>
    <div style="display: flex">
      <div>
        <div>
          <el-select
            v-model="keywords"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            :clearable="true"
            size="mini"
            @change="currentSelect"
            style="width: 500px"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item"
              class="one-text"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.district
              }}</span>
            </el-option>
          </el-select>
        </div>
        <div id="container" class="container"></div>
      </div>
      <div class="info-box">
        纬度：{{ form.lat }}
        <br />
        经度：{{ form.lng }}
        <p>详细地址：{{ form.address }}</p>
      </div>
    </div>
  </template>
   
  <script>
  import AMapLoader from "@amap/amap-jsapi-loader";
  window._AMapSecurityConfig = {
    // 安全密钥
    securityJsCode: "3aca48d28810c22dc5d29b19154c05e0",
  };
  export default {
    name: "TestIndex",
    data() {
      return {
        // 地图实例
        map: null,
        // 标记点
        marker: "",
        // 地址逆解析
        geoCoder: null,
        // 搜索提示
        AutoComplete: null,
        // 搜索关键字
        keywords: "",
        // 位置信息
        form: {
          lng: "",
          lat: "",
          address: "",
          adcode: "", //地区编码
        },
        // 搜索节流阀
        loading: false,
        // 搜索提示信息
        options: [],
      };
    },
    methods: {
      initMap() {
        AMapLoader.load({
          // 你申请的Key
          key: "734354f5c50ad9512dbf698a6ec5323d",
          version: "2.0",
          // 需要用到的插件
          plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
        })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              viewMode: "3D", //是否为3D地图模式
              zoom: 10, //初始化地图级别
              center: [116.397755, 39.903179], //初始化地图中心点位置
            });
            //地址逆解析插件
            this.geoCoder = new AMap.Geocoder({
              city: "010", //城市设为北京，默认：“全国”
              radius: 1000, //范围，默认：500
            });
            // 搜索提示插件
            this.AutoComplete = new AMap.AutoComplete({ city: "全国" });
            //点击获取经纬度;
            this.map.on("click", (e) => {
              // 获取经纬度
              this.form.lng = e.lnglat.lng;
              this.form.lat = e.lnglat.lat;
              // 清除点
              this.removeMarker();
              // 标记点
              this.setMapMarker();
            });
          })
          .catch((err) => {
            // 错误
            console.log(err);
          });
      },
      // 标记点
      setMapMarker() {
        // 自动适应显示想显示的范围区域
        this.map.setFitView();
        this.marker = new AMap.Marker({
          map: this.map,
          position: [this.form.lng, this.form.lat],
        });
        // 逆解析地址
        this.toGeoCoder();
        this.map.setFitView();
        this.map.add(this.marker);
      },
      // 清除点
      removeMarker() {
        if (this.marker) {
          this.map.remove(this.marker);
        }
      },
      // 逆解析地址
      toGeoCoder() {
        let lnglat = [this.form.lng, this.form.lat];
        this.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === "complete" && result.regeocode) {
            this.form.address = result.regeocode.formattedAddress;
          }
        });
      },
      // 搜索
      remoteMethod(query) {
        console.log(query);
        if (query !== "") {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.AutoComplete.search(query, (status, result) => {
              this.options = result.tips;
            });
          }, 200);
        } else {
          this.options = [];
        }
      },
      // 选中提示
      currentSelect(val) {
        // 清空时不执行后面代码
        if (!val) {
          return;
        }
        this.form = {
          lng: val.location.lng,
          lat: val.location.lat,
          address: val.district + val.address,
          adcode: val.adcode,
        };
        // 清除点
        this.removeMarker();
        // 标记点
        this.setMapMarker();
      },
    },
    mounted() {
      this.initMap();
    },
  };
  </script>
   
  <style>
  .container {
    width: 1000px;
    height: 500px;
  }
  </style>