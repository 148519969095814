<template>
    <div class="box" style="width: 100%;">
        <div style="width: 100%;height: 100%;">
            <div class="top">
                <swiper ref="myside" :options="swiperOptions" @click="click">
                    <swiper-slide v-for="item in warn">
                        <span>分店名称：{{ item.device_company }} 故障名称：{{ item.warn_name }} 时间：{{ item.warn_time }}</span>
                        <!-- 分店名称：{{ item. }} -->
                    </swiper-slide>
                </swiper>
            </div>
            <div style="width: 100%;margin: 0 auto">
                <span style="font-size: 20px;font-weight:bolder">设备管理</span>
                <div style="display: flex;justify-content: space-between;">
                    <div class="block" style="display: flex;margin-top: 20px;">
                        <el-form :inline="true">
                            <el-form-item>
                                <div class="item1">
                                    <el-input placeholder="请输入公司名称" v-model="company"
                                        style="width:278px;height:42px;background: #f2f2f2;"></el-input>
                                    <el-input placeholder="请输入所属账户" v-model="username"
                                        style="width:278px;height:42px;background: #f2f2f2;margin-left: 10px;"></el-input>
                                    <el-input placeholder="请输入所在位置" v-model="position"
                                        style="width:278px;height:42px;background: #f2f2f2;margin-left: 10px;"></el-input>
                                </div>

                            </el-form-item>
                        </el-form>
                        <el-button @click="query" type="primary"
                            style="width:100px;height:42px;font-size: 16px;margin-left: 10px;">查询</el-button>
                    </div>
                    <el-button @click="add" type="primary"
                        style="width:100px;height:42px;font-size: 16px;margin-left: 10px;background:#1cd66c;margin-top: 20px;">添加设备</el-button>
                </div>
                <div class="common-tabel" style="margin-top: 10px;padding-top: 10px;">
                    <el-table stripe height="90%" v-loading="loading" element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)"
                        :data="tableData" style="width: 100%," :cell-style="{ 'text-align': 'center' }">
                        <el-table-column width="80" align="center" prop="id" label="id">
                        </el-table-column>
                        <el-table-column align="center" prop="company" label="公司名称">
                        </el-table-column>
                        <el-table-column align="center" prop="imei" label="设备编号">
                        </el-table-column>
                        <el-table-column align="center" prop="manufacture" label="设备厂家">
                        </el-table-column>
                        <el-table-column align="center" prop="username" label="分店名称">
                        </el-table-column>
                        <el-table-column align="center" prop="position" label="所在位置">
                        </el-table-column>
                        <el-table-column width="200" align="center" label="修改设备信息">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="updata(scope.row)">修改信息</el-button>
                                <el-button style="margin-top: 10px" type="danger" size="mini"
                                    @click="handleDelete(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pager">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
        <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible">
            <el-form :model="form" :rules="rules">
                <el-form-item label="设备编号" label-width="120px" prop="imei">
                    <el-input type="text" v-model="form.imei" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" label-width="120px" prop="company">
                    <el-input type="text" v-model="form.company" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分店名称" label-width="120px" prop="username">
                    <el-input type="text" v-model="form.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在位置" label-width="120px" prop="position">
                    <el-input type="text" v-model="form.position" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="设备厂家" label-width="120px">
                    <el-input type="text" v-model="form.manufacture" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="添加新用户" :visible.sync="dialogFormVisible1">
            <el-form :model="form1" :rules="guize">
                <el-form-item label="设备编号" label-width="120px" prop="imei">
                    <el-input type="text" v-model="form1.imei" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所属公司" label-width="120px" prop="company">
                    <el-input type="text" v-model="form1.company" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="分店名称" label-width="120px" prop="username">
                    <el-input type="text" v-model="form1.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在位置" label-width="120px" prop="position">
                    <el-input type="text" v-model="form1.position" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="设备厂家" label-width="120px">
                    <el-input type="text" v-model="form1.manufacture" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submit1">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
    data() {
        return {
            status: '',
            username: '',
            position: '',
            company: '',
            swiperOptions: {
                // loop: true,
                direction: "vertical",
                autoplay: {
                    delay: 2000
                }
            },
            warn: [{}],
            // tableData: [{}],
            // 总数据
            tableData: [],
            // 默认显示第几页
            currentPage: 1,
            // 总条数，根据接口获取数据长度(注意：这里不能为空)
            totalCount: 1,
            // 个数选择器（可修改）
            pageSizes: [12, 20, 30, 40],
            // 默认每页显示的条数（可修改）
            PageSize: 12,
            company: '',
            dialogFormVisible: false,
            dialogFormVisible1: false,
            loading: true,
            form: {
                imei: '',
                company: '',
                username: '',
                position: '',
                manufacture: ''
            },
            form1: {
                imei: '',
                company: '',
                username: '',
                position: '',
                manufacture: ''
            },
            rules: {//验证规则
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                imei: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                username: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                position: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
            },
            guize: {
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                imei: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                username: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                position: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
            }


        }
    },
    created() {
    },
    computed: {
        ...mapState({ loginModule: state => state.loginModule.userinfo }),
    },
    mounted() {
        this.getlist()
        this.getwarn()
        this.timer = setInterval(() => {
            this.getwarn();
        }, 10000);
    },
    methods: {
        click() {
            this.$router.push('/warn');
        },
        handleDelete(row) {
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if (user_role === '1') {
                this.$confirm('此操作将删除设备, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios({
                        url: 'https://www.anbisibiz.cn/device/delete?id=' + row.id,
                        method: 'post',
                        headers: {
                            ['access-token']: jwt
                        }
                    }).then((res => {
                        // console.log(res)
                        if (res.data.status == 200) {
                            this.$message.success('删除设备成功')
                            this.getlist()
                        } else {
                            this.$message.error('删除设备失败')
                            this.getlist()
                        }
                    })).catch((err) => {
                        this.$message.error('删除设备失败')
                    })
                })
            } else {
                this.$message.error('您没有操作权限！')
            }

        },
        getwarn() {
            let jwt = this.loginModule.token
            this.axios({
                url: "https://www.anbisibiz.cn/device/warn",
                method: "get",
                headers: {
                    ['access-token']: jwt
                },
            }).then((res => {
                // console.log(1111, res.data.data)
                this.warn = res.data.data
                // console.log(12121, this.warn)
            })).catch((err => {
                // console.log(err)
            }))
        },
        add() {
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if (user_role === '1') {
                this.dialogFormVisible1 = true
            } else {
                this.$message.error('您没有操作权限！')
            }
        },
        submit1() {
            let jwt = this.loginModule.token
            this.axios({
                url: 'https://www.anbisibiz.cn/device/save',
                method: 'post',
                headers: {
                    ['access-token']: jwt
                },
                data: {
                    ...this.form1
                }
            }).then((res => {
                // console.log(res)
                this.dialogFormVisible1 = false
                if (res.data.status == 200) {
                    this.$message.success('添加设备成功')
                    this.getlist()
                } else {
                    this.$message.error('添加设备失败')
                    this.getlist()
                }
            })).catch((err) => {
                this.$message.error('添加设备失败')
            })
        },
        submit() {
            let jwt = this.loginModule.token
            this.axios({
                url: 'https://www.anbisibiz.cn/device/update',
                method: 'post',
                headers: {
                    ['access-token']: jwt
                },
                data: {
                    ...this.form
                }
            }).then((res => {
                // console.log(res)
                this.dialogFormVisible = false
                if (res.data.status == 200) {
                    this.$message.success('修改成功')
                    this.getlist()
                } else {
                    this.$message.error('修改失败')
                    this.getlist()
                }
            })).catch((err) => {
                this.$message.error('修改失败')
            })
        },
        updata(row) {
            let jwt = this.loginModule.token
            let user_role = this.loginModule.user_role
            if (user_role === '1') {
                this.dialogFormVisible = true
                // if(row.status == true){
                //     this.form.status = '正常用户'
                // }else{
                //     this.form.status = '黑名单用户' 
                // }
                this.form = row
            } else {
                this.$message.error('您没有操作权限！')
            }

        },
        query() {
            this.getlist(),
                this.currentPage = 1
        },
        getlist() {
            this.loading = true
            let jwt = this.loginModule.token
            this.axios({
                url: "https://www.anbisibiz.cn/device/condition",
                method: "post",
                headers: {
                    ['access-token']: jwt
                },
                data: {
                    imei: this.imei,
                    username: this.username,
                    position: this.position,
                    company: this.company,
                    pageIndex: this.currentPage,
                    pageSize: this.PageSize
                }
            }).then((res => {
                // console.log(11111111111, res.data.data)
                this.tableData = res.data.data.result
                this.totalCount = res.data.data.total
                this.loading = false
            }))
        },
        handleSizeChange(val) {
            // 改变每页显示的条数 
            this.PageSize = val
            // 注意：在改变每页显示的条数时，要将页码显示到第一页
            this.currentPage = 1
            this.getlist()
        },
        // 显示第几页
        handleCurrentChange(val) {
            this.currentPage = val
            this.getlist()
        },
    },
    components: {}
}
</script>

<style lang="scss" scoped>
.box {
    display: flex;
}

.top {
    background: red;
    width: 100%;
    height: 30px;
    margin: 10px auto;
}

.swiper-container {
    width: 100%;
    height: 30px;
    border: 1px solid #007acc;
    text-align: center;


    span {
        line-height: 30px;
    }
}

.common-tabel {
    position: relative;
    height: 800px;

    .pager {
        position: absolute;
        bottom: 0;
        right: 20px;
    }
}

/deep/ th {
    padding: 5px;
    font-size: 14px;
    padding-right: 3px;
}

// /deep/ .el-table__row{
//   :hover{
//     background-color: #000000;
//   }
// }
/deep/ td {
    padding: 0px;
    // background: #f5f5f5;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
}

/deep/ .el-table__body {
    border-collapse: separate;
    border-spacing: 0 8px;
    table-layout: auto !important;
}
</style>
