import { render, staticRenderFns } from "./push.vue?vue&type=template&id=71b71332&scoped=true&"
import script from "./push.vue?vue&type=script&lang=js&"
export * from "./push.vue?vue&type=script&lang=js&"
import style0 from "./push.vue?vue&type=style&index=0&id=71b71332&prod&lang=scss&scoped=true&"
import style1 from "./push.vue?vue&type=style&index=1&id=71b71332&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b71332",
  null
  
)

export default component.exports