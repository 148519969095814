<template>
  <div v-loading="loading" element-loading-text="正在登录" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)" class="box">
    <div class="form-box">
      <div class="tit">后台管理登录</div>
            <el-form
              :model="loginForm"
              status-icon
              :rules="rules"
              ref="loginForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="账 号" prop="username">
                <el-input type="text" v-model="loginForm.username"></el-input>
              </el-form-item>
              <el-form-item label="密 码" prop="password">
                <el-input type="password" v-model="loginForm.password"></el-input>
              </el-form-item>
              <div class="catch" style="display: flex;font-size:14px;color: #606266;margin-left: 48px;margin-bottom: 30px;">
                <p>图形验证码图片：</p>
                <img :src="src" @click="img" style="cursor:pointer"/>
              </div>
              <el-form-item label="验证码" prop="captcha">
                <el-input type="text" v-model="loginForm.captcha" maxlength="4"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('loginForm')"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
        <!-- <el-tab-pane label="注册" name="zhuce" >
             <el-form
            :model="loginForm"
            status-icon
            :rules="rules"
            ref="loginForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="账号" prop="username">
              <el-input
                type="text"
                v-model="loginForm.username"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('loginForm')"
                >注册</el-button
              >
              <el-button @click="resetForm('loginForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane> -->
    </div>
  </div>
  </template>
  
  <script>
  import jwt from 'jwt-decode'
  export default {
       data() {
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          }else {
            callback();
          }
        };
        return {
          activeName: 'login',
          loading: false,
          src:'',
          captchaKey:'',
          loginForm: {//登录 表单数据
            username: '',
            password: '',
            captcha:'',
          },
          rules: {//验证规则
            username: [
              { required: true, message: '请输入账号名称', trigger: 'blur' },
              { min: 3, max: 100, message: '请输入三个以上字符', trigger: 'blur' }
            ],
            password: [
              { required: true, message: '请输入密码', trigger: 'blur' },
            ],
            captcha: [
              { required: true, message: '请输入验证码', trigger: 'blur' }
            ]
          }
        };
      },
      created() {
        this.axios.get('https://www.anbisibiz.cn/captcha').then((res=>{
          // console.log(11111,res.data.data)
          this.src = res.data.data.captchaImg,
          this.captchaKey = res.data.data.captchaKey
        })).catch((err=>{
          this.$message.error('获取验证码失败');
        }))
      },
      methods: {
        img(){
          this.axios.get('https://www.anbisibiz.cn/captcha').then((res=>{
          // console.log(11111,res.data.data)
          this.src = res.data.data.captchaImg,
          this.captchaKey = res.data.data.captchaKey
        })).catch((err=>{
          this.$message.error('获取验证码失败');
        }))
        },
        submitForm(formName) {
          this.loading = true
          this.$refs[formName].validate((valid) => {       
            if (valid) {
              let {username,password,captcha} = this.loginForm;
              let captchaKey = this.captchaKey
              // console.log(this.loginForm);
              var that = this
              this.axios(
              {
                url: 'https://www.anbisibiz.cn/login',
                method: 'post',
                params:{
                username,
                password,
                captcha,
                captchaKey
              }
              }).then((response)=>{
            console.log(response.data.status,245);
            if(response.data.status === 200){
              // console.log(response,11111)
              // console.log(response.data.data['user-role'])
              //   console.log(12345,response.data.data['access-token'])
              this.loading = false
                let user = jwt(response.data.data['access-token']).sub;
                let user_role = response.data.data['user-role'];
                let obj= {
                  user,
                  user_role,
                  token:response.data.data['access-token'],
                }
                //存到vuex
                this.$store.commit('loginModule/setUserinfo',obj);
                localStorage.setItem('userinfo',JSON.stringify(obj));
                this.$router.push('/')
            }else{
              this.loading = false
            this.$message.error('账号或密码、验证码不正确，请重新输入');
            this.img()
            
            }
            // 'http://192.168.0.103:8072/api/goodslist',this.qs.stringify(this.loginForm.username,this.sub mitForm.password)
          }).catch(function (err){
            // console.log('服务器连接失败',error);
            this.loading = false
            this.$message.error('服务器连接失败！')
            this.img()
          });
            } else {
              // console.log(334455,'error submit!!');
              this.loading = false
              this.$message.error('账号或密码、验证码不正确，请重新输入');
              this.img()
              return false;
            }
          });
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
      }
  };
  </script>
  
  <style lang='less' scoped>
  .form-box{
      width: 704px;
      height: 501px;
      background-image: url(@/assets/images/page2.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 6%;
      .tit{
        margin-left: 400px;
        margin-top: 50px;
        font-size: 24px;
        font-weight: bolder;
      }
  }
  .box{
    background-image: url(@/assets/images/page1.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .demo-ruleForm{
    margin-top: 100px;
    margin-left: 300px;
    width: 300px;
  }
  </style>