export default {
    namespaced:true,
    state:{
        userinfo:{
            user:'',
            token:'',
            user_role:''
        }
    },
    mutations:{
        //存储
        setUserinfo(state,payload){
            state.userinfo = payload;//{}
        },
        //清空
        deleteUserinfo(state){
            state.userinfo={
                user:'',
                token:'',
                user_role:''
            }
        }
    },
    actions:{

    }
}