import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Home from '../views/Home.vue'
import LiveData from '../views/LiveData.vue'
import Parameter from '../views/Parameter.vue'
import Visual from '../views/Visual.vue'
import Past from '../views/Past.vue'
import Add from '../views/Add.vue'
import User from '../views/User.vue'
import Set from '../views/Set.vue'
import Login from '../views/Login.vue'
import Psi from '../views/Psi.vue'
import Valve from '../views/Valve.vue'
import Warn from '../views/Warn.vue'
import Temperature from '../views/Temperature.vue'
import Switch from '../views/Switch.vue'
import Warn1 from '../views/Warn1.vue'
import Clean from '../views/clean.vue'
import Push from '../views/push.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    redirect: '/home',
    meta:{
      isLogin: true
    },        
    children: [
      // 子路由
      { path:'home', component: Home },
      { path:'liveData', component: LiveData },
      { path:'parameter', component: Parameter },
      { path:'visual', component: Visual },
      { path:'past', component: Past },
      { path:'add', component: Add },
      { path:'user', component: User },
      { path:'set', component: Set },
      { path:'psi', component: Psi },
      { path:'valve', component: Valve },
      { path:'warn', component:Warn },
      { path:'temperature', component: Temperature },
      { path:'switch', component: Switch },
      { path:'warn1', component: Warn1 },
      { path:'clean', component: Clean },
      { path:'push', component: Push }
  ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
},
{
    path:'*',
    redirect:'/'
  }
]

const router = new VueRouter({
  routes
})

export default router
