<template>
    <h1>我是Visual</h1>
</template>

<script type="text/ecmascript-6">
export default {
    data () {
        return {}
    },
    created () { },
    mounted () { },
    methods: {},
    components: {}
}
</script>

<style lang="scss" scoped>
</style>
